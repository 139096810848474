export default [
  {
    name: "Marine x5 (Rifles)",
    abilitiesSelected: [],
    computed: {
      keywords: ["INFANTRY"],
      leaders: [],
      modelCount: 5,
      roles: ["Attacker: Melee", "Attacker: Ranged", "Defender"],
      wounds: 2,
      toughness: 4,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    units: [
      {
        abilities: [],
        id: 1,
        leader: false,
        modelTypes: [
          {
            keywords: ["INFANTRY"],
            modelCount: 5,
            save: 3,
            toughness: 4,
            weapons: [
              {
                name: "Rifle",
                id: 1,
                type: "Ranged",
                attacks: 1,
                strength: 4,
                ap: -1,
                damage: 1,
                countPerUnit: 5,
                bs: 3,
                ws: null,
                abilities: [
                  {
                    aliases: [
                      {
                        name: "Rapid fire 1",
                        type: "reserved",
                      },
                    ],
                    conditions: [
                      {
                        text: "Range",
                        type: "range",
                        data: {
                          range: {
                            title: "Within half range",
                            key: "withinHalfRange",
                          },
                        },
                        description: "Check attack range.",
                        preselected: false,
                      },
                    ],
                    description: "Increase attacks by 1 (within half range)",
                    effect: {
                      text: "Increase weapon attacks",
                      type: "increaseWeaponAttacks",
                      data: {
                        increaseWeaponAttacksValue: 1,
                        applyToExtraAttacks: false,
                      },
                    },
                    id: 1,
                    scope: ["weapon"],
                    aliasActive: true,
                    sharedWithAllUnits: false,
                  },
                ],
                nameUnique: "Rifle",
              },
              {
                name: "Power sword",
                id: 2,
                type: "Melee",
                attacks: 3,
                strength: 5,
                ap: -3,
                damage: 1,
                countPerUnit: 1,
                bs: null,
                ws: 3,
                nameUnique: "Power sword",
                abilities: [],
              },
              {
                name: "Close combat weapon",
                id: 3,
                type: "Melee",
                attacks: 2,
                strength: 4,
                ap: 0,
                damage: 1,
                countPerUnit: 4,
                bs: null,
                ws: 3,
                nameUnique: "Close combat weapon",
                abilities: [],
              },
            ],
            wounds: 2,
            id: 1,
            name: null,
            ignore: null,
            invuln: null,
          },
        ],
        name: null,
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [2, 3],
      ranged: [1],
    },
  },
  {
    name: "Guardsman x10 (Laser guns x9, Laser pistol x1)",
    abilitiesSelected: [],
    computed: {
      keywords: ["INFANTRY"],
      leaders: [],
      modelCount: 10,
      roles: ["Attacker: Melee", "Attacker: Ranged", "Defender"],
      wounds: 1,
      toughness: 3,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    units: [
      {
        abilities: [],
        id: 1,
        leader: false,
        modelTypes: [
          {
            keywords: ["INFANTRY"],
            modelCount: 10,
            save: 5,
            toughness: 3,
            weapons: [
              {
                name: "Laser gun",
                countPerUnit: 9,
                type: "Ranged",
                attacks: 1,
                bs: 4,
                strength: 3,
                ap: 0,
                damage: 1,
                abilities: [
                  {
                    aliases: [
                      {
                        name: "Rapid fire 1",
                        type: "reserved",
                      },
                    ],
                    aliasActive: true,
                    conditions: [
                      {
                        text: "Range",
                        type: "range",
                        data: {
                          range: {
                            title: "Within half range",
                            key: "withinHalfRange",
                          },
                        },
                        description: "Check attack range.",
                        preselected: false,
                      },
                    ],
                    description: "Increase attacks by 1 (within half range)",
                    effect: {
                      text: "Increase weapon attacks",
                      type: "increaseWeaponAttacks",
                      data: {
                        increaseWeaponAttacksValue: "1",
                        applyToExtraAttacks: false,
                      },
                    },
                    scope: ["weapon"],
                    id: 1,
                    sharedWithAllUnits: false,
                  },
                ],
                id: 1,
                nameUnique: "Laser gun",
              },
              {
                name: "Laser pistol",
                countPerUnit: 1,
                type: "Ranged",
                attacks: 1,
                bs: 4,
                strength: 3,
                ap: 0,
                damage: 1,
                id: 2,
                nameUnique: "Laser pistol",
                abilities: [],
              },
              {
                name: "Chainsword",
                countPerUnit: 1,
                type: "Melee",
                attacks: 3,
                ws: 4,
                strength: 3,
                ap: 0,
                damage: 1,
                id: 3,
                nameUnique: "Chainsword",
                abilities: [],
              },
              {
                name: "Close combat weapon",
                countPerUnit: 9,
                type: "Melee",
                attacks: 1,
                ws: 4,
                strength: 3,
                ap: 0,
                damage: 1,
                id: 4,
                nameUnique: "Close combat weapon",
                abilities: [],
              },
            ],
            wounds: 1,
            id: 1,
            name: null,
            ignore: null,
            invuln: null,
          },
        ],
        name: null,
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [3, 4],
      ranged: [1, 2],
    },
  },
  {
    name: "Terminator Equivalent Unit x5",
    abilitiesSelected: [],
    computed: {
      keywords: ["INFANTRY"],
      leaders: [],
      modelCount: 5,
      roles: ["Defender"],
      wounds: 3,
      toughness: 5,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    },
    units: [
      {
        abilities: [],
        id: 1,
        leader: false,
        modelTypes: [
          {
            invuln: 4,
            keywords: ["INFANTRY"],
            modelCount: 5,
            save: 2,
            toughness: 5,
            weapons: [],
            wounds: 3,
            id: 1,
            name: null,
            ignore: null,
          },
        ],
        name: null,
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [],
      ranged: [],
    },
  },
  {
    name: "Avengers",
    abilitiesSelected: [],
    computed: {
      keywords: ["INFANTRY"],
      leaders: [],
      roles: ["Attacker: Melee", "Attacker: Ranged", "Defender"],
      points: 0,
      modelCount: 5,
      wounds: 6,
      toughness: 3,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: "Avenger",
          unitId: 1,
        },
        {
          modelTypeId: 2,
          modelTypeName: "Avenger Exarch",
          unitId: 1,
        },
      ],
    },
    units: [
      {
        abilities: [],
        id: 1,
        leader: false,
        modelTypes: [
          {
            id: 1,
            ignore: null,
            invuln: 5,
            keywords: ["INFANTRY"],
            modelCount: 4,
            name: "Avenger",
            save: 4,
            toughness: 3,
            weapons: [
              {
                name: "Close combat weapon",
                countPerUnit: 4,
                type: "Melee",
                attacks: 2,
                ws: 3,
                strength: 3,
                ap: 0,
                damage: 1,
                id: 1,
                nameUnique: "Close combat weapon",
                abilities: [],
              },
              {
                name: "Catapult",
                countPerUnit: 4,
                type: "Ranged",
                attacks: 3,
                bs: 3,
                strength: 4,
                ap: -1,
                damage: 1,
                abilities: [
                  {
                    aliases: [
                      {
                        name: "Lethal hits",
                        type: "reserved",
                      },
                    ],
                    aliasActive: true,
                    conditions: [
                      {
                        text: "Critical hit/wound",
                        type: "crit",
                        data: {
                          crit: {
                            title: "Critical hit",
                            key: "hitRoll",
                          },
                        },
                        description: 'Require a hit or wound to be "critical".',
                        preselected: false,
                      },
                    ],
                    description: "Critical hit : always wound",
                    effect: {
                      text: "Override requirements",
                      type: "overrideReqs",
                      data: {
                        irrespective: false,
                        outcome: {
                          title: "Wound",
                          key: "wound",
                        },
                        type: {
                          title: "Always",
                          key: "always",
                        },
                      },
                    },
                    scope: ["weapon"],
                    id: 1,
                    sharedWithAllUnits: false,
                  },
                ],
                id: 2,
                nameUnique: "Catapult",
              },
            ],
            wounds: 1,
          },
          {
            id: 2,
            ignore: null,
            invuln: 5,
            keywords: ["INFANTRY"],
            modelCount: 1,
            name: "Avenger Exarch",
            save: 4,
            toughness: 3,
            weapons: [
              {
                name: "Glaive",
                countPerUnit: 1,
                type: "Melee",
                attacks: 3,
                ws: 3,
                strength: 5,
                ap: -2,
                damage: 1,
                id: 3,
                nameUnique: "Glaive",
                abilities: [],
              },
              {
                name: "Pistol",
                countPerUnit: 1,
                type: "Ranged",
                attacks: 1,
                bs: 3,
                strength: 4,
                ap: -1,
                damage: 1,
                id: 4,
                nameUnique: "Pistol",
                abilities: [],
              },
            ],
            wounds: 2,
          },
        ],
        name: "Avengers",
        points: null,
      },
    ],
    weaponsSelected: {
      melee: [1, 3],
      ranged: [2, 4],
    },
  },
  {
    name: "Boys led by Medic",
    abilitiesSelected: [1],
    computed: {
      keywords: ["CHARACTER", "INFANTRY"],
      modelCount: 11,
      roles: ["Attacker: Melee", "Attacker: Ranged", "Defender"],
      toughness: 5,
      wounds: 14,
      leaders: [
        {
          unitId: 1,
          unitName: "Medic",
        },
      ],
      points: 155,
    },
    orderSlain: {
      auto: true,
      order: [
        {
          modelTypeId: 2,
          modelTypeName: "Boys",
          unitId: 2,
        },
        {
          modelTypeId: 3,
          modelTypeName: "Boss Knob",
          unitId: 2,
        },
        {
          modelTypeId: 1,
          modelTypeName: "Medic",
          unitId: 1,
        },
      ],
    },
    units: [
      {
        abilities: [
          {
            aliases: [],
            aliasActive: true,
            conditions: [
              {
                text: "Profile role",
                type: "profileRole",
                data: {
                  profileRole: "Defender",
                },
                description:
                  "Require the current profile to be set as the attacker or defender.",
                preselected: true,
                scope: ["global", "profile", "weapon"],
              },
            ],
            description: "Feel no pain 5+",
            effect: {
              text: "Feel no pain",
              type: "feelNoPain",
              data: {
                value: "5",
              },
            },
            id: 1,
            scope: ["profile"],
            sharedWithAllUnits: true,
            updated: 1720281822589,
          },
        ],
        id: 1,
        leader: true,
        modelTypes: [
          {
            id: 1,
            ignore: null,
            invuln: null,
            keywords: ["CHARACTER", "INFANTRY"],
            modelCount: 1,
            name: "Medic",
            save: 5,
            toughness: 5,
            weapons: [
              {
                name: "Syringe",
                countPerUnit: 1,
                type: "Melee",
                attacks: 1,
                ws: 3,
                strength: 2,
                ap: 0,
                damage: 1,
                abilities: [
                  {
                    aliases: [
                      {
                        name: "Anti-INFANTRY 4+",
                        type: "reserved",
                      },
                    ],
                    aliasActive: true,
                    conditions: [
                      {
                        text: "Keywords (only)",
                        type: "keywordsOnly",
                        data: {
                          keywords: ["INFANTRY"],
                        },
                        description: "Require one or more keywords.",
                        preselected: false,
                      },
                      {
                        text: "Attack step roll",
                        type: "attackStepRoll",
                        data: {
                          attackStep: {
                            title: "Wound roll",
                            key: "woundRoll",
                          },
                          rollReq: {
                            title: "Specific roll",
                            key: "specificRoll",
                          },
                          rollState: {
                            title: "Unmodified",
                            key: "unmodified",
                          },
                          rollTarget: {
                            title: "Or greater",
                            key: "orGreater",
                          },
                          rollValue: "4",
                        },
                        description:
                          "Require a specific roll value in the attack sequence.",
                        preselected: false,
                      },
                    ],
                    description:
                      "Wound roll of 4+ (unmodified) : critical wound (INFANTRY only)",
                    effect: {
                      text: "Override requirements",
                      type: "overrideReqs",
                      data: {
                        crit: true,
                        irrespective: false,
                        outcome: {
                          title: "Wound",
                          key: "wound",
                        },
                        type: {
                          title: "Always",
                          key: "always",
                        },
                      },
                    },
                    scope: ["weapon"],
                    id: 1,
                    sharedWithAllUnits: false,
                  },
                  {
                    aliases: [],
                    aliasActive: true,
                    conditions: [
                      {
                        text: "Critical hit/wound",
                        type: "crit",
                        data: {
                          crit: {
                            title: "Critical wound",
                            key: "woundRoll",
                          },
                        },
                        description: 'Require a hit or wound to be "critical".',
                        preselected: false,
                        scope: ["global", "profile", "weapon"],
                      },
                      {
                        text: "Keywords (excludes)",
                        type: "keywordsExcludes",
                        data: {
                          keywords: ["VEHICLE"],
                        },
                        description: "Exclude one or more keywords.",
                        preselected: false,
                        scope: ["global", "profile", "weapon"],
                      },
                    ],
                    description:
                      "Critical wound : D6 mortal wounds (excludes VEHICLE)",
                    effect: {
                      text: "Mortal wounds",
                      type: "mortalWounds",
                      data: {
                        attackSequenceEnds: false,
                        cap: null,
                        matchWeaponDamage: false,
                        mortalWoundsValue: "D6",
                      },
                    },
                    id: 2,
                    scope: ["weapon"],
                    sharedWithAllUnits: false,
                  },
                ],
                id: 1,
                nameUnique: "Syringe",
              },
              {
                name: "Power claw",
                countPerUnit: 1,
                type: "Melee",
                attacks: 3,
                ws: 4,
                strength: 9,
                ap: -2,
                damage: 2,
                id: 2,
                nameUnique: "Power claw",
                abilities: [],
              },
            ],
            wounds: 3,
          },
        ],
        name: "Medic",
        points: 70,
      },
      {
        abilities: [],
        id: 2,
        leader: false,
        modelTypes: [
          {
            id: 2,
            ignore: null,
            invuln: null,
            keywords: ["INFANTRY"],
            modelCount: 9,
            name: "Boys",
            save: 5,
            toughness: 5,
            weapons: [
              {
                name: "Chopper",
                countPerUnit: 9,
                type: "Melee",
                attacks: 3,
                ws: 3,
                strength: 4,
                ap: -1,
                damage: 1,
                id: 3,
                nameUnique: "Chopper",
                abilities: [],
              },
              {
                name: "Slugger",
                countPerUnit: 9,
                type: "Ranged",
                attacks: 1,
                bs: 5,
                strength: 4,
                ap: 0,
                damage: 1,
                id: 4,
                nameUnique: "Slugger (Boys)",
                abilities: [],
              },
            ],
            wounds: 1,
          },
          {
            id: 3,
            ignore: null,
            invuln: null,
            keywords: ["INFANTRY"],
            modelCount: 1,
            name: "Boss Knob",
            save: 5,
            toughness: 5,
            weapons: [
              {
                name: "Big chopper",
                countPerUnit: 1,
                type: "Melee",
                attacks: 3,
                ws: 3,
                strength: 7,
                ap: -1,
                damage: 2,
                id: 5,
                nameUnique: "Big chopper",
                abilities: [],
              },
              {
                name: "Slugger",
                countPerUnit: 1,
                type: "Ranged",
                attacks: 1,
                bs: 5,
                strength: 4,
                ap: 0,
                damage: 1,
                id: 6,
                nameUnique: "Slugger (Boss Knob)",
                abilities: [],
              },
            ],
            wounds: 2,
          },
        ],
        name: "Boys",
        points: 85,
      },
    ],
    weaponsSelected: {
      melee: [1, 2, 3, 5],
      ranged: [4, 6],
    },
  },
]
